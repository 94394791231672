let screenWidth = $(window).width();
let cardsSlick = $('.cards__items');

const TARRIFS_SHOW = 3; // Number of tariffs to preload

$(cardsSlick).each(function(){
    let parent = $(this).closest('.cards');

    let cardsAmount = $(parent).find('.card').length;
    //console.log(cardsAmount);

    // [MOB ONLY] Hide extra cards on mob devices
    if(screenWidth < 577){
        $i = 0;
        $(parent).find('.card').each(function(){
            $i++;
            if($i < (TARRIFS_SHOW + 1)) return;
            $(this).css('display', 'none');
        });

        $diff = cardsAmount - TARRIFS_SHOW; // Diff with currently showing cards

        if($diff > 0) $(parent).append(`<button class="btn btn_default btn_white load-all">Show next ${$diff} tariffs</button>`);
    }

    // On slick item changed
    $(this).on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        let i = (currentSlide ? currentSlide : 0) + 1;
        $temp = slick.slideCount - 2;
        let pages = $temp <= 0 ? slick.slideCount : $temp;
        console.log(i + ':' +pages);

        if(i == pages && i == 1) return; // No display if one page

        $(parent).find('.status').html(i + '/' + pages);
    });

    // Init slick slider
    $(this).slick({
        rows: 0,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,

        adaptiveHeight: true,
        variableWidth: true,
        swipeToSlide: true,
        arrows: true,
        appendArrows: $(parent).find('.cards__nav'),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  variableWidth: false
                }
            },
            {
              breakpoint: 902,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                variableWidth: false
              }
            },
            {
                breakpoint: 577,
                settings: "unslick"
            }
        ]
    });
});

$(function(){

    // [MOB ONLY] Load another hidden tariffs
    $(document).on('click', '.load-all', function () {
        if(screenWidth > 576) return;
        $(this).closest('.cards').find('.card:hidden').fadeIn(300);
        $(this).remove();
    });

    // Show more details
    $(document).on('click', '.show-more', function () {
        if(screenWidth < 577){
            $('.show-more').css('display', 'block');
            $('.card__details').css('display', 'none');
            $('.hide').css('display', 'none');

            let card = $(this).closest('.card');
            $(card).find('.card__details').fadeIn(300);
            $(`<button class="btn btn_default btn_white hide mob-hide">Hide</button>`).insertAfter(card);
            $(this).fadeOut(300);
            $(card).find('.btn_default').css('margin-bottom', '35px');
        } else {
            let tab = $(this).attr('data-tab');
            $(tab).find('.show-more').fadeOut(300);
            $(tab).find('.btn_default').css('margin-bottom', '35px');
            $(tab).find('.card__details').fadeIn(300);
            $(tab).find('.hide').css("display", "block").hide().fadeIn();
        }
    });

    // Hide more details
    $(document).on('click', '.hide', function () {
        let tab = $(this).attr('data-tab');

        // Tab is null on mobile :)
        if(tab == null){
            $('.show-more').fadeIn(300);
            $('.cards .btn_default').css('margin-bottom', '20px');
            $('.card__details').fadeOut(300);
        } else {
            $(tab).find('.show-more').fadeIn(300);
            $(tab).find('.btn_default').css('margin-bottom', '20px');
            $(tab).find('.card__details').fadeOut(300);
        }

        $(this).css("display", "none");
    });

    // Mob menu
    $(document).on('click', '.burger', function () {
        let mobMenu = $('.mob-menu');
        if(mobMenu == null) return;
 
        if( $(mobMenu).is(':visible') ){
             $(this).attr('src', 'assets/images/burger.svg');
             $(mobMenu).slideUp(300);
        } else {
             $(this).attr('src', 'assets/images/burger_close.svg');
             $(mobMenu).slideDown(300);
        }
     });

});